<template>
  <div :class="['ui-input', { error: error, required: requiredField, search: search, 'placeholder-wrap': placeholderWrap }]">
    <label v-if="label" :for="label" class="ui-input__label">{{  error ? errorText || label : label }}</label>
    <div class="ui-input__inner">
      <img v-if="!showPassword && search"
           class="ui-input__inner-search-icon"
           src="@/assets/svg/search/search.svg"
           alt=""
           @click="$emit('search')"
      />
      <input
          :type="inputType"
          :id="label"
          :value="value"
          @input="$emit('input', $event.target.value)"
          @keypress="$emit('keypress', $event)"
          @focus="$emit('focus')"
          :placeholder="placeholder"
          :readonly="readOnly"
          ref="input"
      >
      <img
          v-if="showPassword"
          class="ui-input__inner-icon"
          :src="getPasswordIcon"
          alt=""
          @click="changeInputType"
      />
      <img v-if="!showPassword && value && !readOnly"
           class="ui-input__inner-icon"
           src="@/assets/svg/close/grey.svg"
           alt=""
           @click="$emit('input', '')"
      />
    </div>
  </div>

</template>

<script>
export default {
  name: "UiInput",

  props: {
    value: String,
    label: String,
    search: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    showPassword: {
      type: Boolean,
      default: false,
    },
    requiredField: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: String,
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      inputType: this.showPassword ? 'password' : 'text',
      placeholderWrap: false
    }
  },
  computed: {
    getPasswordIcon() {
      return this.inputType === 'password' ? require('@/assets/svg/close/eye-close.svg') : require('@/assets/svg/icon-eye-open.svg')
    }
  },
  methods: {
    changeInputType () {
      this.inputType = this.inputType === 'text' ? 'password' : 'text'
    }

  },
  mounted() {
    if(this.$refs.input.placeholder.length > 50) {
      this.placeholderWrap = true
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.required {

    .ui-input__label:after {
      content: '*';
      color: #E21F1F;
    }
  }

  &.error {

    .ui-input {

      &__label {
        animation: 0.25s shake;
        color: #E21F1F;
      }

      &__inner {

        input {
          border-color: #E21F1F;
        }
      }
    }
  }

  &.search {
    input {
      padding-left: 45px;
      background: #FFFFFF;
    }
  }

  &.placeholder-wrap {

    input {

      &::placeholder {
        position: relative;
        top: -7px;
      }

    }

  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #343432;
    margin: 0 0 0 30px;
    transition: 0.25s;
  }

  &__inner {
    position: relative;

    input {
      width: 100%;
      height: 40px;
      border: 1px solid #9A9A9A;
      border-radius: 200px;
      background: transparent;
      padding: 15px 33px 14px 15px;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #343432;
      outline: none;
      transition: 0.25s;
    }

    &-icon {
      position: absolute;
      cursor: pointer;
      right: 9px;
      top: 50%;
      transform: translateY(-50%);
    }

    &-search-icon {
      position: absolute;
      cursor: pointer;
      left: 20px;
      top: 25%;
    }
  }
}
input {
  &::placeholder {
    white-space: pre-line;
  }
}
//::-webkit-input-placeholder { /* Chrome/Opera/Safari */
//  white-space:pre-line;
//
//}
//::-moz-placeholder { /* Firefox 19+ */
//  white-space:pre-line;
//}
//:-ms-input-placeholder { /* IE 10+ */
//  white-space:pre-line;
//}
//:-moz-placeholder { /* Firefox 18- */
//  white-space:pre-line;
//}
</style>
